<template>
  <!-- <a-button @click="generateAdminPDF()" :loading="loading" :disabled="loading">
    <a-icon v-if="!loading" type="file-pdf" />
    {{ $t("emploi.imprimerEmploiAdministrative") }}
  </a-button>
 -->

  <a-dropdown>
    <template #overlay>
      <a-menu
        @click="
          (val) => {
            if (val.key == '1') generateAdminPDF('ar');
            else generateAdminPDF('fr');
          }
        "
      >
        <a-menu-item key="1">
          {{ $t("language.ar") }}
        </a-menu-item>
        <a-menu-item key="2">
          {{ $t("language.fr") }}
        </a-menu-item>
      </a-menu>
    </template>
    <a-button :loading="loading" :disabled="loading">
      <a-icon v-if="!loading" type="file-pdf" />{{
        $t("emploi.imprimerEmploiAdministrative")
      }}
    </a-button>
  </a-dropdown>
</template>
<script>
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import "jspdf-autotable";
export default {
  name: "adminPDFschedule",
  props: ["trimester"],
  computed: {
    ...mapState(["settings", "user"]),
  },
  methods: {
    async getData() {
      await apiClient
        .post("/schedules/filter", {
          query: {
            status: { $ne: "inactive" },
          },
        })
        .then(({ data }) => {
          this.data = data.map((sched) => {
            if (sched.type == "MP") sched.subject = sched.module;
            else if (sched.type == "MS") sched.subject = sched.moduleSpecific;

            sched.subject.type = sched.type;

            return sched;
          });
        });
    },
    getPosition(time) {
      let position = time.split(":")[1];
      return Number(position / 15);
    },

    availableRow(row, position, duration) {
      for (let i = position; i < position + duration; i++)
        if (row[i] !== null) return false;
      return true;
    },

    async generateAdminPDF(lang = "fr") {
      this.$gtag.event("Imp Emploi admin", {
        event_category: "Impression PDF",
        event_label: "Emploi - classe section",
        value: 1,
      });

      this.loading = true;
      await this.getData();
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF("landscape");
      doc.setFont("Amiri");
      doc.addImage(imgData, "JPEG", 8, 5, 30, 30);

      doc.setFont("Amiri");
      doc.setFontSize(15);
      var today = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.setFontSize(10);

      doc.text(
        230,
        10,
        lang == "fr"
          ? "Imprimé le : " + " " + today.toLocaleDateString("fr-FR", options)
          : " تم الطباعة بتاريخ " + today.toLocaleDateString("ar-TN", options)
      );
      doc.setFontSize(lang == "ar" ? 20 : 18);
      doc.setFont("Amiri");

      const pdfWidth = doc.internal.pageSize.getWidth();

      const title =
        lang == "fr" ? `Emplois administrative` : `جدول الأوقات الإداري`;
      const width = doc.getTextWidth(title);
      doc.text(pdfWidth / 2 - width / 2, 30, title);

      const days =
        lang == "fr"
          ? ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
          : ["الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"];

      const times = [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ];
      //margin top of the page
      let top = 40;

      const cellToDelete = {};

      let timesHeader = [];
      for (let i = 0; i < times.length - 1; i++) {
        timesHeader[i] = {
          content: times[i] + " - " + times[i + 1],
          styles: {
            halign: "center",
            valign: "middle",
            lineWidth: 0.05,
          },
          colSpan: 4,
        };
      }

      for (let dayNumber = 0; dayNumber < days.length; dayNumber++) {
        const array = [new Array(40).fill(null)];

        for (let i = 0; i < times.length - 1; i++) {
          let schedules = this.data.filter(
            (el) =>
              times[i].split(":")[0] == el.time.split(":")[0] &&
              el.day == dayNumber
          );
          schedules.forEach((sched) => {
            const schedName =
              sched.type == "MP"
                ? sched.module.name
                : sched.type == "MS"
                ? sched.moduleSpecific.name
                : sched.subject.name;
            const cellValue =
              (sched.name ? sched.name : schedName) +
              "\r\n" +
              sched.teacher.employee.firstName +
              " " +
              sched.teacher.employee.lastName +
              "\r\n" +
              sched.classeRoom.name;
            //  +
            // "\r\n" +
            // sched.room.name;

            // get the time position ex: H:00 -> 0, H:15 -> 1, H:30 -> 2, H:45 -> 3
            let position = this.getPosition(sched.time);

            // check for available row
            let row = array.findIndex((row) =>
              this.availableRow(row, i * 4 + position, sched.duration)
            );
            // else add new one
            if (row === -1) {
              array.push(new Array(40).fill(null));
              row = array.length - 1;
            }

            // add the sched to the selected row
            array[row][i * 4 + position] = {
              content: cellValue,
              styles: { halign: "center", valign: "middle" },
              colSpan: sched.duration,
            };

            // for fixing colspans
            for (let t = 1; t < sched.duration; t++)
              array[row][i * 4 + position + t] = cellToDelete;
          });
        }

        // fix table's colspans
        for (let row = 0; row < array.length; row++) {
          array[row] = array[row].filter((cell) => cell !== cellToDelete);
        }

        if (dayNumber != 0) {
          doc.addPage();
          top = 15;
        }
        doc.autoTable({
          theme: "grid",
          head: [
            [
              {
                content: days[dayNumber],
                colSpan: 10 * 4,
                styles: {
                  halign: "center",
                  fillColor: "#169c80",
                  fontSize: lang == "ar" ? 17 : 15,
                  textColor: "#ffffff",
                },
              },
            ],
            timesHeader,
          ],
          body: array,
          styles: { font: "Amiri" },
          margin: { top: top },
          columnStyles: {
            0: { cellWidth: 6.5 },
            1: { cellWidth: 6.5 },
            2: { cellWidth: 6.5 },
            3: { cellWidth: 6.5 },
            4: { cellWidth: 6.5 },
            5: { cellWidth: 6.5 },
            6: { cellWidth: 6.5 },
            7: { cellWidth: 6.5 },
            8: { cellWidth: 6.5 },
            9: { cellWidth: 6.5 },
            10: { cellWidth: 6.5 },
            11: { cellWidth: 6.5 },
            12: { cellWidth: 6.5 },
            13: { cellWidth: 6.5 },
            14: { cellWidth: 6.5 },
            15: { cellWidth: 6.5 },
            16: { cellWidth: 6.5 },
            17: { cellWidth: 6.5 },
            18: { cellWidth: 6.5 },
            19: { cellWidth: 6.5 },
            20: { cellWidth: 6.5 },
            21: { cellWidth: 6.5 },
            22: { cellWidth: 6.5 },
            23: { cellWidth: 6.5 },
            24: { cellWidth: 6.5 },
            25: { cellWidth: 6.5 },
            26: { cellWidth: 6.5 },
            27: { cellWidth: 6.5 },
            28: { cellWidth: 6.5 },
            29: { cellWidth: 6.5 },
            30: { cellWidth: 6.5 },
            31: { cellWidth: 6.5 },
            32: { cellWidth: 6.5 },
            33: { cellWidth: 6.5 },
            34: { cellWidth: 6.5 },
            35: { cellWidth: 6.5 },
            36: { cellWidth: 6.5 },
            37: { cellWidth: 6.5 },
            38: { cellWidth: 6.5 },
            39: { cellWidth: 6.5 },
          },
          headStyles: {
            valign: "middle",
            halign: "center",
            fontSize: 10,
            fontStyle: "bold",
          },
        });
      }
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      this.loading = false;
      //end page numbering
      doc.save(title + ".pdf");
    },
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
};
</script>
